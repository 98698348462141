import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: "https://c5086362a255406c9bb8e8247923c299@o1186798.ingest.sentry.io/6306619",
        integrations: [new BrowserTracing(), new Sentry.Replay()],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.1,
        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,
        // An array of strings or regexps that'll be used to ignore specific errors based on their type/message
        ignoreErrors: [/isTrigger, jQuery/],
    });
    window.Sentry = Sentry
}